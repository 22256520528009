import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Typography,
  CardContent,
  CardMedia,
  Grid,
  TextField,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import baseURL from '../../url';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../styles.css";

import Loading from '../Loading';
import Sessions from "./Sessions";
import DashboardMenu from "./DashboardMenu";
import BankInfo from './BankInfo';
import StreamerGuide from './StreamerGuide';
import { differenceInDays } from 'date-fns';
import { supportedGames, otherSessionTypes } from '../../config';
import GoogleEventCreator from './GoogleEventCreator';

const Dashboard = () => {
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const userId = localStorage.getItem('userId');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // show streamer guide until user has been registered for 14 days
  const showStreamerGuide = user?.is_creator && differenceInDays(new Date(), new Date(user.created_at)) <= 90;

  useEffect(() => {
    if (user) {  // Only update these state values if user data has been fetched
      setHourlyRate(user.hourly_rate || '');
      setStreamChannelUrl(user.stream_channel_url || '');
      setActivisionId(user.activision_id || '');
      setEpicUsername(user.epic_username || '');
      setGamertag(user.gamertag || '');
      setRiotId(user.riot_id || '');
      setRockstarSocialClubAccount(user.rockstar_social_club_account || '');
      setDeadByDaylightId(user.dead_by_daylight_id || '');
      setEAId(user.ea_id || '');
      setName(user.name || '');
      setSelectedGames((user.games || []));
      setSelectedOtherSessionTypes((user.other_session_types || []));
    }
  }, [user]);  // T                 his useEffect runs whenever the user state value changes

  const [hourlyRate, setHourlyRate] = useState(user?.hourly_rate || '');
  const [streamChannelUrl, setStreamChannelUrl] = useState(user?.stream_channel_url || '');
  const [activisionId, setActivisionId] = useState(user?.activision_id || '');
  const [epicUsername, setEpicUsername] = useState(user?.epic_username || '');
  const [riotId, setRiotId] = useState(user?.riot_id || '');
  const [rockstarSocialClubAccount, setRockstarSocialClubAccount] = useState(user?.rockstar_social_club_account || '');
  const [deadByDaylightId, setDeadByDaylightId] = useState(user?.dead_by_daylight_id || '');
  const [gamertag, setGamertag] = useState(user?.gamertag || '');
  const [eaId, setEAId] = useState(user?.ea_id || '');
  const [name, setName] = useState(user?.name || '');
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = useState('dashboard');
  const [minimumLoadingTimePassed, setMinimumLoadingTimePassed] = useState(false);
  const [selectedGames, setSelectedGames] = useState([]);
  const [selectedOtherSessionTypes, setSelectedOtherSessionTypes] = useState([]);
  const [showEventCreator, setShowEventCreator] = useState(false);

  const handleCreateEventClick = () => {
    setShowEventCreator(true);
  };

  const handleCancelCreateEvent = () => {
    setShowEventCreator(false);
    setLoading(false);
  }

  useEffect(() => {
    // Set a timeout to simulate the spinner for a full second
    const timer = setTimeout(() => {
      setMinimumLoadingTimePassed(true);
    }, 1000); // 1000 milliseconds = 1 second

    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');

    let intervalId;
    let timeoutId;

    if (status === 'success') {
      setLoading(true);

      const checkUserStripeConnected = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/users/${userId}`);
          if (response.data.stripe_connected) {
            setLoading(false);
            toast.success('Stripe account connected successfully!');
            setUser(response.data);
            clearInterval(intervalId);
            clearTimeout(timeoutId); // Clear the timeout when successful
          }
        } catch (error) {
          setLoading(false);
          toast.error('Failed to connect Stripe account. Please try again.');
          console.error('Error fetching user data:', error);
        }
      };

      intervalId = setInterval(checkUserStripeConnected, 5000); // Poll every 5 seconds

      // Set a timeout to stop polling after 30 seconds
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        setLoading(false);
        throw new Error('Polling timeout: Stripe account connection check failed after 30 seconds.');
      }, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Cleanup on unmount
      }
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear the timeout on unmount
      }
    };
  }, [userId]);

  const fetchUser = async () => {
    setLoading(true);  // Set loading to true at the beginning
    try {
      const response = await axios.get(`${baseURL}/api/users/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
    setLoading(false);  // Set loading to false immediately after fetching data or if there's an error
  };


  useEffect(() => {
    setLoading(true);  // Set loading to true at the beginning

    if (userId) {
      fetchUser();  // Execute fetchUser if userId is defined
    }

  }, [userId]);  // Re-run this useEffect whenever userId changes


  const toggleEditing = () => {
    setIsEditing(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'hourlyRate') setHourlyRate(value);
    if (name === 'streamChannelUrl') setStreamChannelUrl(value);
    if (name === 'activisionId') setActivisionId(value);
    if (name === 'name') setName(value);
    if (name === 'epicUsername') setEpicUsername(value);
    if (name === 'riotId') setRiotId(value);
    if (name === 'rockstarSocialClubAccount') setRockstarSocialClubAccount(value);
    if (name === 'gamertag') setGamertag(value);
    if (name === 'deadByDaylightId') setDeadByDaylightId(value);
    if (name === 'eaId') setEAId(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedHourlyRate = parseFloat(hourlyRate);

    // Check if the hourly rate is below the minimum threshold
    if (updatedHourlyRate < 1) {
      toast.error('The minimum hourly rate is $1.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Exit the function to prevent further execution
    }

    const updatedData = {
      hourly_rate: updatedHourlyRate,
      activision_id: activisionId,
      epic_username: epicUsername,
      riot_id: riotId,
      rockstar_social_club_account: rockstarSocialClubAccount,
      gamertag,
      ea_id: eaId,
      dead_by_daylight_id: deadByDaylightId,
      name,
      games: selectedGames,
      other_session_types: selectedOtherSessionTypes,
      stream_channel_url: streamChannelUrl,
    };

    try {
      const response = await axios.put(`${baseURL}/api/users/${user.id}`, updatedData);
      if (response.status === 200) {
        // Pass the updated user data to the handleEditSuccess function
        handleEditSuccess(response.data.editedUser);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditSuccess = (updatedUser) => {
    setUser(updatedUser); // Update the user state with the new user data
    setIsEditing(false);
    toast.success('Profile updated successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleGameSelect = (game) => {
    if (selectedGames.includes(game)) {
      setSelectedGames(selectedGames.filter(g => g !== game));
    } else {
      setSelectedGames([...selectedGames, game]);
    }
  };

  const handleOtherSessionTypeSelect = (game) => {
    if (selectedOtherSessionTypes.includes(game)) {
      setSelectedOtherSessionTypes(selectedOtherSessionTypes.filter(g => g !== game));
    } else {
      setSelectedOtherSessionTypes([...selectedOtherSessionTypes, game]);
    }
  }

  const handleBankInfoAdded = async () => {
    // Re-fetch user data to update the UI
    await fetchUser();  // Assuming fetchUser is the function you use to get user data
    toast.success('Bank information added successfully!');
  };

  if (loading || !minimumLoadingTimePassed || !user) return <Loading />;

  const noChangesMade = hourlyRate === user?.hourly_rate &&
    activisionId === user?.activision_id &&
    riotId === user?.riot_id &&
    epicUsername === user?.epic_username &&
    rockstarSocialClubAccount === user?.rockstar_social_club_account &&
    gamertag === user?.gamertag &&
    deadByDaylightId === user?.dead_by_daylight_id &&
    eaId === user?.ea_id &&
    name === user?.name &&
    selectedGames === user?.games &&
    streamChannelUrl === user?.stream_channel_url &&
    selectedOtherSessionTypes === user?.other_session_types;

  return (
    !isMobile ? (
      <Grid container spacing={0} style={{ minHeight: '90vh', margin: 'auto' }}>
        {/* Menu - 1st quarter */}
        <Grid item xs={3} style={{ padding: '1em' }}>
          <DashboardMenu setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} isMobile={isMobile} />
        </Grid>

        {/* Content - 2nd and 3rd quarter */}
        <Grid item xs={6} style={{ padding: '1em', }}>
          {selectedMenu === 'dashboard' && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "95%", margin: "auto" }}>
              <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
              {user.is_creator && !user.stripe_connected && !user.manual_payout ? (
                <BankInfo userId={userId} isMobile={isMobile} onBankInfoAdded={handleBankInfoAdded} />
              ) : null}
              <Grid container direction="column" alignItems="center" justifyContent="center">
                {showStreamerGuide ? <StreamerGuide /> : null}
                {showEventCreator ?
                  <GoogleEventCreator creatorId={userId} onCancel={handleCancelCreateEvent} isMobile={isMobile} setLoading={setLoading} loading={loading} />
                  :
                  <Card style={{ borderRadius: "1rem" }} sx={{ maxWidth: 600, textAlign: 'center' }}>
                    {/* Removed CardActionArea for non-clickable card */}
                    <CardMedia
                      component="img"
                      alt="Profile Picture"
                      image={user.profile_picture || 'default_profile_pic_url'}
                      title="Profile Picture"
                      sx={{ maxWidth: 400, height: 'auto', maxHeight: 400 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {user.creator_name || activisionId || name}
                      </Typography>
                    </CardContent>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      {!showEventCreator && (user?.is_creator || user?.is_manager) && (
                        <Button variant="contained" onClick={handleCreateEventClick} sx={{ width: '60%', margin: "0 0 2rem 0", padding: "1rem 0", fontSize: "1rem" }}>
                          Create Open Session
                        </Button>
                      )}
                      <Button variant={isEditing ? "outlined" : "contained"} color={isEditing ? "secondary" : "primary"} onClick={toggleEditing} sx={{ width: '60%', margin: "0 0 2rem 0", padding: "1rem 0", fontSize: "1rem" }}>
                        {isEditing ? 'Cancel Editing' : 'Edit Profile'}
                      </Button>
                    </div>
                  </Card>
                }
                {isEditing ? (
                  <form
                    onSubmit={handleSubmit}
                    style={{ maxWidth: '600px', margin: 'auto', marginTop: '20px' }}
                  >
                    {user?.is_creator ? (
                      <>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="hourlyRate"
                          label="Hourly Rate"
                          name="hourlyRate"
                          type="number"
                          autoComplete="hourly-rate"
                          autoFocus
                          value={hourlyRate}
                          onChange={handleInputChange}
                        />

                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="streamChannelUrl"
                          label="Stream Channel URL"
                          name="streamChannelUrl"
                          type="text"
                          autoFocus
                          value={streamChannelUrl}
                          onChange={handleInputChange}
                        />

                        <div style={{ marginTop: '20px', textAlign: "Center" }}>
                          <p>Select Games Available for Sessions</p>
                          <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)', // Creates four columns
                            justifyContent: 'center',
                            gap: '10px',
                            margin: "1rem"
                          }}>
                            {supportedGames.map((game) => (
                              <Button
                                key={game}
                                variant={selectedGames.includes(game) ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => handleGameSelect(game)}
                                style={{
                                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                  boxShadow: selectedGames.includes(game) ? `0 0 10px ${theme.palette.primary.light}` : 'none',
                                  textTransform: 'none',
                                  minHeight: "62px",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.boxShadow = `0 0 10px ${theme.palette.primary.light}`;
                                  e.currentTarget.style.transform = 'scale(1.05)';
                                }}
                                onMouseOut={(e) => {
                                  if (!selectedGames.includes(game)) {
                                    e.currentTarget.style.boxShadow = 'none';
                                  }
                                  e.currentTarget.style.transform = 'scale(1)';
                                }}
                              >
                                {game}
                              </Button>
                            ))}
                          </div>
                        </div>

                        <div style={{ marginTop: '20px', textAlign: "Center" }}>
                          <p>Select Other Available Sessions</p>
                          <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)', // Creates four columns
                            justifyContent: 'center',
                            gap: '10px',
                            margin: "1rem"
                          }}>
                            {otherSessionTypes.map((game) => (
                              <Button
                                key={game}
                                variant={selectedOtherSessionTypes.includes(game) ? "contained" : "outlined"}
                                color="primary"
                                onClick={() => handleOtherSessionTypeSelect(game)}
                                style={{
                                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                  boxShadow: selectedOtherSessionTypes.includes(game) ? `0 0 10px ${theme.palette.primary.light}` : 'none',
                                  textTransform: 'none',
                                  minHeight: "62px",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.boxShadow = `0 0 10px ${theme.palette.primary.light}`;
                                  e.currentTarget.style.transform = 'scale(1.05)';
                                }}
                                onMouseOut={(e) => {
                                  if (!selectedOtherSessionTypes.includes(game)) {
                                    e.currentTarget.style.boxShadow = 'none';
                                  }
                                  e.currentTarget.style.transform = 'scale(1)';
                                }}
                              >
                                {game}
                              </Button>
                            ))}
                          </div>
                        </div>

                      </>
                    ) : null}
                    {!user?.is_creator ?
                      <>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="name"
                          label="Name"
                          name="name"
                          autoComplete="name"
                          value={name}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="activisionId"
                          label="Activision ID"
                          name="activisionId"
                          autoComplete="activisionId"
                          value={activisionId}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="epicUsername"
                          label="Epic Username"
                          name="epicUsername"
                          autoComplete="epicUsername"
                          value={epicUsername}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="gamertag"
                          label="Gamertag"
                          name="gamertag"
                          autoComplete="gamertag"
                          value={gamertag}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="riotId"
                          label="Riot ID"
                          name="riotId"
                          autoComplete="riotId"
                          value={riotId}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="eaId"
                          label="EA/Origin ID"
                          name="eaId"
                          autoComplete="eaId"
                          value={eaId}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="rockstarSocialClubAccount"
                          label="Rockstar Social Club"
                          name="rockstarSocialClubAccount"
                          autoComplete="rockstarSocialClubAccount"
                          value={rockstarSocialClubAccount}
                          onChange={handleInputChange}
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="deadByDaylightId"
                          label="Dead by Daylight ID"
                          name="deadByDaylightId"
                          autoComplete="deadByDaylightId"
                          value={deadByDaylightId}
                          onChange={handleInputChange}
                        />
                      </>
                      : null}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ width: '70%', margin: "1rem 0 2rem 0", padding: "1rem 0", fontSize: "1rem" }}
                        disabled={noChangesMade}
                      >
                        Update
                      </Button>
                    </div>
                  </form>
                ) : null}
              </Grid>
            </div>
          )}
          {selectedMenu === 'sessions' ? <Sessions isMobile={isMobile} user={user} /> : null}
        </Grid>

        {/* Empty space - 4th quarter */}
        <Grid item xs={3} />
      </Grid >
    ) : (
      <div style={{ padding: '1em' }}>
        {/* Mobile Menu Component at the top */}
        <DashboardMenu setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} isMobile={isMobile} />

        {/* Mobile version of Player Card and other contents */}
        <div style={{ width: '90%', margin: '5% auto' }}>
          {selectedMenu === 'dashboard' && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", margin: "auto" }}>
              <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
              {user.is_creator && !user.stripe_connected && !user.manual_payout ? (
                <BankInfo userId={userId} isMobile={isMobile} onBankInfoAdded={handleBankInfoAdded} />
              ) : null}
              {showStreamerGuide ? <StreamerGuide /> : null}
              {showEventCreator ?
                <GoogleEventCreator creatorId={userId} onCancel={handleCancelCreateEvent} isMobile={isMobile} setLoading={setLoading} loading={loading} />
                :
                <Card style={{ borderRadius: "1rem", width: '100%' }}>
                  <CardMedia
                    component="img"
                    alt="Profile Picture"
                    image={user.profile_picture || 'default_profile_pic_url'}
                    title="Profile Picture"
                    style={{ width: '100%', height: 'auto' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{ textAlign: "center" }}>
                      {user.creator_name || activisionId || name}
                    </Typography>
                  </CardContent>
                  <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center', margin: '0 0 2rem 0' }}>
                    {!showEventCreator && (user?.is_creator || user?.is_manager) && (
                      <Button variant="contained" onClick={handleCreateEventClick}style={{ width: '80%', padding: ".8rem 0", marginBottom: ".6rem" }}>
                        Create Open Session
                      </Button>
                    )}
                    <Button variant={isEditing ? "outlined" : "contained"} color={isEditing ? "secondary" : "primary"} onClick={toggleEditing} style={{ width: '80%', padding: ".8rem 0" }}>
                      {isEditing ? 'Cancel Editing' : 'Edit Profile'}
                    </Button>
                  </div>
                </Card>
              }
              {isEditing && (
                <form onSubmit={handleSubmit} style={{ width: '100%', margin: 'auto', marginTop: '20px' }}>
                  {user?.is_creator ? (
                    <>
                      <div style={{ marginTop: '20px', textAlign: "Center" }}>
                        <p>Select Games Available for Sessions</p>
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(3, 1fr)', // Creates four columns
                          justifyContent: 'center',
                          gap: '10px',
                          margin: "1rem"
                        }}>
                          {supportedGames.map((game) => (
                            <Button
                              key={game}
                              variant={selectedGames.includes(game) ? "contained" : "outlined"}
                              color="primary"
                              onClick={() => handleGameSelect(game)}
                              style={{
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                boxShadow: selectedGames.includes(game) ? `0 0 10px ${theme.palette.primary.light}` : 'none',
                                textTransform: 'none',
                              }}
                            >
                              {game}
                            </Button>
                          ))}
                        </div>
                      </div>

                      <div style={{ marginTop: '20px', textAlign: "Center" }}>
                        <p>Select Other Available Sessions</p>
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(3, 1fr)', // Creates four columns
                          justifyContent: 'center',
                          gap: '10px',
                          margin: "1rem"
                        }}>
                          {selectedOtherSessionTypes.map((game) => (
                            <Button
                              key={game}
                              variant={selectedOtherSessionTypes.includes(game) ? "contained" : "outlined"}
                              color="primary"
                              onClick={() => handleOtherSessionTypeSelect(game)}
                              style={{
                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                boxShadow: selectedOtherSessionTypes.includes(game) ? `0 0 10px ${theme.palette.primary.light}` : 'none',
                                textTransform: 'none',
                              }}
                            >
                              {game}
                            </Button>
                          ))}
                        </div>
                      </div>

                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="streamChannelUrl"
                        label="Stream Channel URL"
                        name="streamChannelUrl"
                        type="text"
                        autoFocus
                        value={streamChannelUrl}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="hourlyRate"
                        label="Hourly Rate"
                        name="hourlyRate"
                        type="number"
                        autoComplete="hourly-rate"
                        autoFocus
                        value={hourlyRate}
                        onChange={handleInputChange}
                      />
                    </>
                  ) : null}
                  {!user?.is_creator ? (
                    <>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        value={name}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="activisionId"
                        label="Activision ID"
                        name="activisionId"
                        autoComplete="activisionId"
                        value={activisionId}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="epicUsername"
                        label="Epic Username"
                        name="epicUsername"
                        autoComplete="epicUsername"
                        value={epicUsername}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="riotId"
                        label="Riot ID"
                        name="riotId"
                        autoComplete="riotId"
                        value={riotId}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="gamertag"
                        label="Gamertag"
                        name="gamertag"
                        autoComplete="gamertag"
                        value={gamertag}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="rockstarSocialClubAccount"
                        label="Rockstar Social Club"
                        name="rockstarSocialClubAccount"
                        autoComplete="rockstarSocialClubAccount"
                        value={rockstarSocialClubAccount}
                        onChange={handleInputChange}
                      />
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="deadByDaylightId"
                        label="Dead by Daylight ID"
                        name="deadByDaylightId"
                        autoComplete="deadByDaylightId"
                        value={deadByDaylightId}
                        onChange={handleInputChange}
                      />
                    </>
                  ) : null}
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ margin: "1rem", width: '90%', padding: ".8rem 0" }}
                      disabled={noChangesMade}
                    >
                      Update
                    </Button>
                  </div>
                </form>
              )}
            </div>
          )}
          {selectedMenu === 'sessions' ? <Sessions isMobile={isMobile} user={user} /> : null}
        </div>
      </div>
    )
  );
};

export default Dashboard;
